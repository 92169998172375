import React from 'react'
import { Flex } from 'rebass'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Line = styled.span`
  display: block;
  width: 69px;
  height: 4px;
`

const FancyHeading = ({
  heading,
  icon,
  IconComponent,
  description,
  textColor = '#000',
  lineColor = '#000',
  ...props
}) => {
  return (
    <Flex alignItems="center" flexDirection="column" {...props}>
      <h2
        css={css`
          color: ${textColor};
          margin-top: 0;
          text-align: center;
        `}
      >
        {heading}
      </h2>
      <Flex alignItems="center">
        <Line
          css={css`
            background: ${lineColor};
          `}
        />

        {!!icon && (
          <img
            src={icon}
            aria-hidden
            alt=""
            css={css`
              display: block;
              width: 55px;
              height: auto;
              margin: 0 18px;
            `}
          />
        )}

        {!!IconComponent && (
          <IconComponent
            css={css`
              display: block;
              font-size: 40px !important;
              height: auto;
              margin: 0 18px;
              color: ${textColor};
            `}
          />
        )}
        <Line
          css={css`
            background: ${lineColor};
          `}
        />
      </Flex>
      {description && (
        <p
          css={css`
            color: ${textColor};
            margin-bottom: 0;
            text-align: center;
            max-width: 566px;
          `}
        >
          {description}
        </p>
      )}
    </Flex>
  )
}

export default FancyHeading
