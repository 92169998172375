import React from 'react'
import { Box, Flex } from 'rebass'
import FancyHeading from './FancyHeading'
import { css } from '@emotion/core'
import { mq } from '../theme'
import Slant from './Slant'
import { useStaticQuery, graphql } from 'gatsby'
import AccordianItem from './AccordianItem'

const ServicesSection = ({ heading, image, description }) => {
  const data = useStaticQuery(graphql`
    query Services {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "services-page" } } }
        sort: { order: ASC, fields: frontmatter___date }
      ) {
        edges {
          node {
            frontmatter {
              shortTitle
              description
              iconLight {
                publicURL
              }
              iconDark {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.edges

  return (
    <>
      <Slant>
        <Box variant="container">
          <FancyHeading
            heading={heading}
            icon={image.publicURL}
            description={description}
            textColor={'#fff'}
            lineColor="#fff"
            css={css`
              margin-top: 35px;
              margin-bottom: 35px;
            `}
          />

          <div
            css={css`
              ${mq.md} {
                display: none;
              }
            `}
          >
            {services.map(({ node: service }, idx) => (
              <AccordianItem
                key={idx}
                label={service.frontmatter.shortTitle}
                icon={service.frontmatter.iconDark.publicURL}
              >
                <h3>
                  {service.frontmatter.title || service.frontmatter.shortTitle}
                </h3>
                <p>{service.frontmatter.description}</p>
              </AccordianItem>
            ))}
          </div>
        </Box>
      </Slant>
      <Slant
        color="white"
        css={css`
          display: none;
          ${mq.md} {
            display: block;
          }
        `}
        last
      >
        <Box
          variant="container"
          css={css`
            margin-top: 70px;
          `}
        >
          <Flex flexWrap="wrap" justifyContent="space-between">
            {services.map(({ node: service }, idx) => {
              return (
                <Box
                  key={idx}
                  width={{ _: '32.5%', lg: '32%' }}
                  css={css`
                    background: #004aad;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                    padding: 8px;
                  `}
                >
                  <div
                    css={css`
                      border: 4px solid #fff;
                      height: 100%;
                      padding: 20px;

                      ${mq.xl} {
                        padding: 40px;
                      }
                    `}
                  >
                    <h3
                      css={css`
                        margin-top: 0;
                      `}
                    >
                      {service.frontmatter.shortTitle}
                    </h3>
                    <img
                      src={service.frontmatter.iconLight.publicURL}
                      css={css`
                        width: 40px;
                        height: 40px;

                        ${mq.xl} {
                          width: 80px;
                          height: 80px;
                        }
                      `}
                      alt=""
                      aria-hidden
                    />
                    <p
                      css={css`
                        font-size: 16px;
                        margin: 0;
                        margin-top: 15px;

                        ${mq.xl} {
                          font-size: 22px;
                        }
                      `}
                    >
                      {service.frontmatter.description}
                    </p>
                  </div>
                </Box>
              )
            })}
          </Flex>
        </Box>
      </Slant>
    </>
  )
}

export default ServicesSection
