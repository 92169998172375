import React from 'react'
import { css } from '@emotion/core'
import { mq } from '../theme'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const TapToCallCta = ({
  telephone,
  eventAction = 'Tap',
  eventCategory = 'call_us_cta',
  eventLabel = 'Sticky mobile cta',
}) => {
  return (
    <OutboundLink
      href={`tel:${telephone}`}
      css={css`
        position: fixed;
        z-index: 9999;
        height: 95px;
        left: 0px;
        right: 0;
        bottom: 0px;
        background: linear-gradient(180deg, #004aad 0%, #003377 100%), #004aad;
        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.55);
        color: #fff;
        text-decoration: none;
        font-size: 38px;
        line-height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

        ${mq.md} {
          display: none;
        }
      `}
      eventCategory={eventCategory}
      eventLabel={eventLabel}
      eventAction={eventAction}
    >
      Tap here to call
    </OutboundLink>
  )
}

export default TapToCallCta
