import React from 'react'
import { css } from '@emotion/core'

import { mq } from '../theme'

const Slant = ({ children, color = '#004aad', last = false, ...props }) => {
  const height = 95
  const slantHeight = height * 1.5

  return (
    <div
      css={css`
        background: ${color};
        position: relative;
        min-height: ${height * 2}px;
        margin-bottom: 102px;

        &:before,
        &:after {
          position: absolute;
          z-index: 1;
          width: 100%;
          content: '';
          background: inherit;
          right: 0;
          left: 0;
          transform: skewY(-7deg);
          transform-origin: top left;
          height: ${slantHeight}px;

          ${mq.md} {
            transform: skewY(-5deg);
          }

          ${mq.xxl} {
            display: none;
          }
        }

        &:before {
          top: 0px;
        }

        &:after {
          bottom: -${slantHeight}px;
        }

        ${last &&
        `
            margin-bottom:0;
            &:after { display:none; }
        `}
      `}
      {...props}
    >
      <div
        css={css`
          position: relative;
          z-index: 2;
          padding-top: 20px;
          padding-bottom: 20px;
        `}
      >
        {children}
      </div>
    </div>
  )
}

export default Slant
