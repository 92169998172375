import React from 'react'
import Slant from './Slant'
import { Box } from '@material-ui/core'
import FancyHeading from './FancyHeading'
import StarIcon from '@material-ui/icons/StarBorderOutlined'
import { css } from '@emotion/core'
import { Flex } from 'rebass'
import { mq } from '../theme'

const IconContainer = (props) => (
  <Flex
    as="ul"
    css={css`
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      width: 100%;
      max-width: 450px;

      ${mq.lg} {
        max-width: 1010px;
      }

      margin: 0 auto;
    `}
    {...props}
    flexWrap="wrap"
  />
)

const Icon = ({ label, color, last, ...props }) => {
  const width = last ? '100%' : '50%'
  return (
    <Flex
      as="li"
      css={css`
        color: ${color};
        width: ${width};

        ${mq.lg} {
          width: 20%;
        }
        margin-top: 30px;
      `}
      justifyContent="center"
    >
      <div>
        <p
          css={css`
            font-size: 28px;
            font-weight: bold;
            line-height: 120%;
            margin: 10px;
          `}
        >
          {label}
        </p>
        <Flex
          css={css`
            width: 166px;
            height: 166px;
            border-radius: 50%;
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          `}
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            css={css`
              width: 144px;
              height: 144px;
              background: #ffffff;
              border: 7px solid ${color};
              border-radius: 50%;
            `}
            justifyContent="center"
            alignItems="center"
          >
            <p
              css={css`
                font-size: 25px;
                font-weight: bold;
              `}
            >
              <span
                css={css`
                  font-size: 51px;
                `}
              >
                10
              </span>
              /10
            </p>
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}

const TrustRating = () => {
  return (
    <Slant color="#fff">
      <Box
        variant="container"
        css={css`
          margin-top: 45px;
          margin-bottom: 45px;

          ${mq.lg} {
            margin-top: 70px;
            margin-bottom: 70px;
          }
        `}
      >
        <FancyHeading
          heading="5-Star Trust Rating"
          IconComponent={StarIcon}
          lineColor="#004AAD"
          textColor="#1C2C36"
        />

        <IconContainer>
          <Icon label="Reliability" color="#0094cb" />
          <Icon label="Courtesy" color="#E4C256" />
          <Icon label="Tidiness" color="#79CD77" />
          <Icon label="Workmanship" color="#71D6CE" />
          <Icon label="Quote Accuracy" color="#006DA0" last />
        </IconContainer>
      </Box>
    </Slant>
  )
}

export default TrustRating
