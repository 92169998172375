import React from 'react'

import { css } from '@emotion/core'
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver'
import Star from '@material-ui/icons/StarRate'

import Slant from './Slant'
import FancyHeading from './FancyHeading'
import { theme, mq } from '../theme'
import { Flex, Box } from 'rebass'

const TestimonialsSection = ({ heading, description, testimonials }) => {
  const rows = []
  let rowIdx = 0

  testimonials.map((testimonial, idx) => {
    if (!rows[rowIdx]) {
      rows[rowIdx] = []
    }

    rows[rowIdx].push(testimonial)

    if ((idx + 1) % 3 === 0) rowIdx++
  })

  return (
    <>
      <Slant>
        <Box variant="container">
          <FancyHeading
            heading={heading}
            description={description}
            textColor="#fff"
            lineColor="#fff"
            IconComponent={RecordVoiceOver}
            css={css`
              margin-top: 20px;
              margin-top: 40px;
            `}
          />
        </Box>
      </Slant>
      {rows.map((row, idx) => (
        <Slant key={idx} color={idx % 2 === 0 ? '#fff' : theme.colors.primary}>
          <Box
            variant="container"
            css={css`
              margin-top: 70px;
              margin-bottom: 70px;
            `}
          >
            <Flex>
              {row.map((testimonial, childIdx) => {
                const color = idx % 2 !== 0 ? '#fff' : theme.colors.primary
                return (
                  <Flex
                    className={!!childIdx ? 'screenReaderMobile' : ''}
                    width={{ md: '33%' }}
                    key={childIdx}
                    flexDirection="column"
                    alignItems="center"
                    css={css`
                      color: ${color};
                    `}
                  >
                    <Box>
                      {[...Array(5)].map((e, i) => (
                        <Star
                          key={i}
                          color={color}
                          css={css`
                            font-size: 40px !important;
                          `}
                        />
                      ))}
                    </Box>
                    <Box
                      css={css`
                        text-align: center;
                      `}
                    >
                      <p
                        css={css`
                          font-size: 20px;
                          white-space: pre-wrap;
                          padding: 10px;

                          ${mq.md} {
                            font-size: 16px;
                          }
                        `}
                      >
                        {testimonial.content}
                      </p>
                      <p
                        css={css`
                          font-size: 24px;
                          font-weight: bold;
                          margin-bottom: 0;
                        `}
                      >
                        {testimonial.author} via {testimonial.source}
                      </p>
                    </Box>
                  </Flex>
                )
              })}
            </Flex>
          </Box>
        </Slant>
      ))}
    </>
  )
}

export default TestimonialsSection
