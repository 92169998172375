import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Flex, Box } from 'rebass'
import OpenIcon from '@material-ui/icons/AddBox'
import CloseIcon from '@material-ui/icons/IndeterminateCheckBox'

const Button = ({ width, open, onClick }) => (
  <Flex
    width={width}
    as="button"
    justifyContent="center"
    alignItems="center"
    css={css`
      border: 0;
      cursor: pointer;
      outline: 0;
    `}
    onClick={onClick}
  >
    {!!open ? (
      <CloseIcon
        css={css`
          display: block;
          color: #004aad;
          font-size: 3em !important;
        `}
      />
    ) : (
      <OpenIcon
        css={css`
          display: block;
          color: #004aad;
          font-size: 3em !important;
        `}
      />
    )}
  </Flex>
)

const IconHolder = ({ width, icon }) => (
  <Flex
    width={width}
    css={css`
      min-height: 82px;
      background: #fff;
      padding: 5px;
    `}
    alignItems="center"
    justifyContent="center"
  >
    <img
      src={icon}
      css={css`
        width: 70px;
        display: block;
      `}
      alt=""
    />
  </Flex>
)

const LabelHolder = ({ width, label }) => (
  <Box
    width={width}
    as="p"
    css={css`
      font-size: 24px;
      margin-left: 10px;
    `}
    alignSelf="center"
    aria-hidden
  >
    {label}
  </Box>
)

const AccordianItem = ({ icon, label, children, ...props }) => {
  const [open, toggleOpen] = useState(false)

  return (
    <>
      <Flex
        css={css`
          background: #efefef;
          border-top: 5px solid #004aad;
        `}
        {...props}
      >
        <IconHolder width="20%" icon={icon} />
        <LabelHolder width="63%" label={label} />
        <Button width="17%" open={open} onClick={() => toggleOpen(!open)} />
      </Flex>
      <div
        css={css`
          background: #3c3c3c;
          color: #fff;
          overflow-y: hidden;
          max-height: ${open ? '400px' : 0};
          transition: 0.5s all ease;
        `}
      >
        <div
          css={css`
            padding: 10px 20px;
          `}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default AccordianItem
